<template>
    <div class="close-header-title no-con">
        <section class="privacy-section">
            <div class="container">
                <div class="main-heading">
                    <h1>Privacy Policy</h1>
                    <p>This Privacy Policy, which is incorporated into our
                        outlines our privacy practices in connection with your use of our websites and any
                        related applications and services (together, the "Website" or "Service").</p>
                </div>
                <div class="content-section center">
                    <h2>What does this Privacy Policy cover?</h2>
                    <p>This Privacy Policy explains how we, CheapComfort LLC ("CheapComfort," "us," "we,"
                        or "our"), collect, use, and disclose personal information. This Privacy Policy only
                        applies to the Service and where we control data collection and use. It does not apply
                        to any third-party websites, actions, or offers, regardless of links, bookings, or
                        connections through the Website. We do not control third parties, which each have
                        separate terms and privacy practices.</p>
                    <p>Our Website connects users with third-party providers ("Provider(s)") of Cheapcomforts
                        properties and services. We do not own or sell bookings for Cheapcomforts properties.
                        If you book a Cheapcomforts using the Website, your booking and payment information
                        will be collected on behalf of and transmitted to the Provider that makes the property
                        available for booking.</p>
                    <p>We collect and use information primarily to provide and improve the Service. For
                        example, when you visit our Website, you may provide information to us to use the
                        Service, and we may also generate technical device and cookie data. This allows us to
                        provide and market our offerings. Generally, we share information with our service
                        providers and any partners to facilitate our Service and show offers that may be of
                        interest to you. We also collect booking and payment information for the respective
                        Provider to assist with your third-party booking. We care about user privacy and employ
                        measures for data security and to respond to consumer privacy requests, such as to
                        access information we have collected about you, delete your information, and opt out of
                        certain practices. Read each section below for more information.</p>
                    <h2>When do we collect your information?</h2>
                    <p>Generally, we collect personal information when you provide it to us or the public, such
                        as when you search on the Website. Like many websites, we also obtain technical and
                        device information when you visit or use the Website. Further, we may collect
                        information when it is relevant to the Service, including from third parties, such as
                        device, internet, and geolocation information from technical processes, such as when
                        you access the Website, and your browser sends device and/or internet information.
                        CheapComfort may also collect information you provide to us or content you make
                        public outside of the Website, such as when you contact us through customer service or
                        social media, for example by posting a review about CheapComfort or tagging
                        CheapComfort in a post.</p>
                    <p>If you create or use an account, including through email, Google, or Facebook
                        sign-in/up, we may collect information relating to your sign-in/up, and if you use,
                        link, or add to a "favorites" list, we collect information related to your activity.
                        Also, if you interact with us on social media or use features, such as plugins,
                        widgets, or other tools made available by social media platforms or networks (including
                        Facebook, Instagram, and Twitter) in connection with our Website, we collect
                        information that you disclose to us, or that the social media platforms disclose to us.
                        For example, when you share properties that are available on the Website through social
                        media platforms, we may track and use this information. For more information about the
                        privacy practices of those social media platforms, please review their separate privacy
                        policies and your settings for the social media platforms you use.</p>
                    <p>Further, if you book a property rental through the Service, your booking data will be
                        collected for and on behalf of the Provider when you checkout.</p>
                    <h2>What categories of information do we collect?</h2>
                    <p>Typically, we collect information that is relevant to the Service, such as data that
                        allows you to use the Service and connect you with Providers.</p>
                    <p>Specifically, we may collect the following categories of personal information when you
                        use the Service in a manner aside from making a booking:</p>
                    <p><em>Identifying Information</em>, such as first and last name, email address, and phone
                        number; account name and information. If you use, link, or add to a "favorites" list,
                        we collect information related to your activity.</p>
                    <p><em>Commercial Information</em>, such as transactional data about when you click on a
                        listed property.</p>
                    <p><em>Technical Information</em>, such as device, internet, and network information,
                        including data from cookies and related technologies, search and browsing history
                        related to the Service and interaction with our marketing, and geolocation information
                        from a device IP address.</p>
                    <p><em>Public Information</em>, such as when you post a review about CheapComfort.</p>
                    <p><em>Other Information</em>, such as communications to us, your responses to any
                        promotions, or non-personalized information like public or aggregate data. You should
                        take caution in publicizing or uploading any sensitive information. We may also create
                        new information about you by drawing inferences from the information identified in the
                        categories above.</p>
                    <p>In addition, we may collect the following categories of personal information when you
                        checkout and fill out a form on the Website to book with a Provider:</p>
                    <p><em>Identifying Information</em>, such as first and last name, address, email address,
                        phone number, and date of birth.</p>
                    <p><em>Commercial and Financial Information</em>, such as transactional data about your
                        booking and credit card information to be transmitted to the Provider, which may also
                        be transmitted to a Provider's payment processor. We do not store your financial
                        information when you book through the Website.</p>
                    <p><em>Technical Information</em>, such as device, internet, and network information.</p>
                    <h2>How is your information used?</h2>
                    <p>Generally, we use information to provide and improve the Service, to personalize the
                        user experience, and for relevant marketing.</p>
                    <p>Specifically, we may use personal information for these reasons:</p>
                    <p><em>Providing the Service</em>, such as to operate and provide the Website. For example,
                        if you provide account information, we use it to allow you to access your account and
                        related features. If you book with a Provider through the Website, we act as a service
                        provider for the Provider and pass on your checkout and booking information to the
                        Provider for the Provider's use. However, we may collect and use information
                        separately, such as if you agree to receive marketing communications from us, such as
                        emails about offers.</p>
                    <p><em>Improving the Service</em>, such as to review whether other features are desired.</p>
                    <p><em>Personalizing the Service</em>, such as by using cookies or other technologies to
                        save certain data for limited time periods so you do not need to enter it again.
                        Additionally, we use information about your account and favorited listings to allow you
                        to save listings.</p>
                    <p><em>Communications</em>, such as to respond to your requests or questions. This may
                        include business-related or legal communications, replies to your inquiries, or
                        marketing communications (which are outlined further below).</p>
                    <p><em>Security and Auditing</em>, such as to detect security incidents, protect against
                        malicious, fraudulent, or illegal activity, and to take action if applicable. We may
                        also use information to authenticate requests or for other security reasons.</p>
                    <p><em>Comply with Legal Obligations</em>, such as to comply with a court order or subpoena
                        or to transfer privacy requests to relevant third parties.</p>
                    <p><em>For Aggregate Data</em>, meaning we may combine your information with that of other
                        users to create "Aggregate Data" – namely, statistical information about all users or a
                        subset thereof. Aggregate Data is pseudonymized and deidentified, i.e., it cannot be
                        used to identify, relate to, describe, be capable of being associated with, or be
                        linked, directly or indirectly, to a particular consumer, including you. Aggregate Data
                        may be used by CheapComfort for the business and commercial purposes listed in this
                        Privacy Policy.</p>
                    <p><em>Marketing to You</em>, such as to use information about you, your account, and you
                        favorited listings to email you relevant content. Note that if you book through the
                        Website, the respective Provider may also market offers to you. We may also use
                        information gathered through cookies and web beacons, including those placed by third
                        parties, to deliver ads to you via the Website, and after you've left the Website,
                        including advertising specific to your personal interests. Similarly, we may use this
                        information to draw inferences or create a profile about you. The purpose of
                        "personalized ads," "retargeting," or "remarketing" is to deliver content and
                        advertising that is of interest to you and to avoid displaying irrelevant advertising
                        to you. For more information.</p>
                    <p><em>Advertising</em>, such as by sharing your publicly available information, for
                        example a public review or comment about CheapComfort, to advertise on the Website or
                        social media.</p>
                    <p><em>Other Uses</em> due to your request or consent and as authorized by law. For
                        example, we may use your contact information if you sign up for a promotion.</p>
                    <p>These uses further our business purposes as reasonably necessary and proportionate to
                        our operations, such as provision and protection of the Service, as well as commercial
                        purposes, such as marketing relevant offerings. We similarly process your information
                        pursuant to our legal bases in providing and improving the Service, communicating with
                        you, complying with our legal obligations, preventing fraud, advertising to you,
                        promoting safety and security, responding to requests, with your consent, where
                        necessary to perform a contract, where processing is necessary to protect your or
                        another person's vital or legitimate interests if not overridden by fundamental rights
                        or freedoms, and where necessary to comply with a legal obligation.</p>
                    <h2>How is your information disclosed to others?</h2>
                    <p>Generally, we disclose information for the same business and commercial reasons that we
                        collect and use information, such as to provide and improve the Service, to comply with
                        legal obligations, and for relevant marketing.</p>
                    <p>Specifically, we may disclose information under these circumstances:</p>
                    <p><em>To Providers</em> when you make a booking through the Website. In particular, when
                        you checkout and fill out a form to book with a Provider through the Website, the
                        information you submit and related to your booking is transmitted to the Provider. Your
                        data includes identifying information, such as name, email address, phone number, and
                        date of birth, as well as payment information, such as credit card information, to make
                        your booking.</p>
                    <p><em>To Service Providers or Contractors</em>, such as email or hosting services or
                        customer support, who may review information you provide or that is part of your use of
                        the Service to assist us in providing the Service to you. We may disclose information
                        for purposes related to our Website, such as to provide information to service
                        providers, for security purposes, to personalize user experience, and to analyze
                        aggregate data.</p>
                    <p><em>To Legal Parties</em>, who may require disclosure of data as authorized by
                        applicable law in response to a subpoena, court order, or other legal process. This may
                        include transfer of information to relevant third parties to fulfill a privacy or other
                        legal request. We may also disclose information if we believe it is necessary to
                        prevent or remedy illegal or harmful misconduct, including to law enforcement or to our
                        legal, professional, or other advisors. We may disclose information to comply with
                        CheapComfort's legal obligations and to prevent illegal or harmful misconduct.</p>
                    <p><em>To Marketing Partners</em>, affiliates, platforms, advertisers, or third parties,
                        who may obtain information you provide as well as device, internet, and technical
                        information, to provide personalized and other advertisements that may be of interest
                        to you, including through email if you subscribe to our content. If you are signed in
                        using a third-party account (e.g., Google or Facebook), that third party may collect
                        your information as outlined in their separate privacy policy. We may disclose
                        information to advertise to you, such as through personalized advertisements.
                        Additionally, if you interact with CheapComfort through a social media platform
                        (e.g., click "Like" on one of our posts on Facebook or Instagram), we may share
                        information with the social media company that you have visited or interacted with us.</p>
                    <p><em>To Third Parties or the Public</em>, if the information is public, such as a review
                        posted about us online, or if it cannot reasonably identify or be linked to you, such
                        as aggregate data used to evaluate the Service and users as a whole. We may disclose
                        public information in marketing or advertising efforts.</p>
                    <p><em>To Related Parties</em> if we are involved in an acquisition, merger, sale, or
                        similar transaction, in which case we may transfer or assign your information.</p>
                    <p><em>To Others</em> due to your request or consent and as authorized by law.</p>
                    <p><em>For Data Selling or Sharing</em> for the purposes described and as outlined to you
                        in this Privacy Policy. Specifically, certain transfers of data through cookies and
                        similar technologies, as described below, may be considered selling or sharing under
                        certain laws (which may or may not apply to us or to you under various legal
                        thresholds). You may opt out of such practices by using opt-out links on our Website
                        (which will be displayed to certain users, if required by applicable legislation) or
                        contacting us as outlined below.</p>
                    <h2>Do we use cookies or automated technologies?</h2>
                    <p>We, like many websites, may use cookies (files that are placed on your computer or
                        electronic device when you visit a website), pixels, web beacons, and similar
                        technologies to automatically collect certain device, internet, browser, and
                        geolocation information when you use the Website.</p>
                    <p>Collected data may include mobile device identifier or MAC address, ISP or carrier
                        information, date and time you access the Website, the pages you visit, and whether you
                        click on certain content or advertisements. We may use this information to personalize
                        parts of the Service, determine when content is accessed, and customize advertisements.
                        These technologies may include necessary and preference cookies, such as to display the
                        Website and comply with preferences set by you, as well as analytics and advertising
                        cookies, such as to understand how users use the Service and to deliver ads.</p>
                    <p>For example, we may use Google cookies and tools to provide and improve our Service and
                        for analytics and advertising. For more information, visit Google's policies.</p>
                    <p>CheapComfort may also use third-party cookies to improve the Website and your user
                        experience and advertise offers of interest to you. In other words, your information
                        may be shared with these third parties and vice versa, depending on your device and
                        account settings.<b>Online behavioral advertising ("OBA") and/or multi-site advertising
                            is occurring on the Website, and third-party cookies may be used to provide a more
                            targeted advertising experience both on and off the Website</b>.</p>
                    <p>You may opt out of cookies or change your cookie settings in your internet browser or
                        disable similar tracking technologies in your mobile phone or other device. Resources
                        for these technologies and opting out include.</p>
                    <p>Note that the Website does not currently respond to DNT requests (DNT is a feature
                        offered by some browsers which, when enabled, sends a signal to websites to request
                        that your browsing is not tracked).</p>
                    <h2>Do we collect information about children?</h2>
                    <p>You must be at least 18 years old to use the Website. We do not knowingly collect
                        personal information about children under the age of 13. If we obtain actual knowledge
                        that we have collected personal information about children under the age of 13, we will
                        delete that information.</p>
                    <h2>How is your information protected and retained?</h2>
                    <p>We process and transfer data to the United States. While we use reasonable security
                        measures and tools that are designed to be a security safeguard, and we take data
                        security seriously, no system or website can be 100% secure. You should not expect that
                        information communicated using our Service or the internet generally will be secure.
                        You are responsible for maintaining the security and confidentiality of your accounts
                        and devices and should immediately notify us if you believe there has been a relevant
                        security breach.</p>
                    <p>While we retain data related to your use of the Website, including certain information
                        related to your booking if applicable, we do not store or process any payment
                        information, which is processed by the respective Provider.</p>
                    <p>We will retain your information for the length of time necessary to fulfill the business
                        and commercial purposes outlined in this Privacy Policy or as otherwise authorized by
                        law, considering these factors: ongoing services or open requests; legal obligations
                        for data retention; and other relevant considerations, including legal agreements and
                        any pending inquiries.</p>
                    <h2>What are your privacy rights?</h2>
                    <p>Certain users have privacy rights, such as to access and delete personal information we
                        have collected, as outlined further below. If you have a specific privacy request
                        regarding your information, please contact us with your name and email address (or
                        other identifier used with the Service) using our contact information below. To protect
                        user privacy and security, we may require that you, or your agent if applicable, verify
                        the identity of the requestor. Please note that if your request is to a Provider, you
                        should contact the Provider.</p>
                    <p><em>Access</em> your information, including for certain users such as California
                        residents, to know the categories of information we have collected and the sources, our
                        related business and commercial purposes, the categories of third parties to whom data
                        is disclosed, and the information we have collected about you. Under California's
                        "Shine the Light" law, California residents who provide personal information may
                        request information regarding disclosure of personal information to third parties for
                        their direct marketing purposes.</p>
                    <p><em>Delete</em> your information, subject to certain legal limitations.</p>
                    <p><em>Amend</em> your information if it is inaccurate.</p>
                    <p><em>Limit</em> the processing of certain information or any automated decision making.</p>
                    <p><em>Opt Out</em> of certain practices, such as any selling or sharing of information, or
                        marketing communications. For more information, use the relevant opt-out links on the
                        Website or follow the prompts in the related communications. If you opt out of
                        advertisements, we may still send you non-promotional messages, such as related to your
                        use of the Service or legal updates. If you wish to opt out of Provider communications,
                        please contact the Provider.</p>
                    <p><em>Non-Discrimination</em> for assertion of your privacy rights or filing a complaint.</p>
                    <p><em>Other Rights</em>, depending on your place of residence. For example, if you reside
                        in Europe, you have the right to request certain copies of your personal data,
                        including in a structured, commonly used, and machine-readable format or to request
                        transmission of the information to a service provider where feasible; to ask us to
                        correct inaccurate or incomplete data about you; to delete your personal data, subject
                        to certain limitations (and we may retain your data as necessary for legitimate
                        business interests, such as security and legal reasons, to comply with legal
                        obligations, if information is already public, and if data is aggregated); to withdraw
                        consent for processing by logging into your account or contacting us; to limit how we
                        use your data; to object to processing of data, such as for direct marketing purposes
                        or for a purpose based on legitimate or public interests, by contacting us as outlined
                        below; and to lodge complaints by contacting us or a supervisory authority, including
                        the European Data Protection Authorities.</p>
                    <p>The following are our legal bases for the processing of your information, e.g., in
                        providing and improving the Service, communicating with you, complying with our legal
                        obligations, preventing fraud, advertising to you, promoting safety and security, and
                        responding to requests: Where we have your consent for such processing, where necessary
                        to perform a contract with you, where processing is necessary to protect your or
                        another person's vital interests, where processing is necessary for our or a third
                        party's legitimate interests, unless they are overridden by your interests or
                        fundamental rights or freedoms, and where necessary for us to comply with a legal
                        obligation.</p>
                    <h2>How do we modify our Privacy Policy?</h2>
                    <p>The version of this Privacy Policy posted on the Website is the current version, and if
                        any modifications are made, we will post the then-current version. By continuing to
                        access or use our Service after any modifications are posted, you agree to be bound by
                        the modifications. Any modified Privacy Policy will only apply as of the updated date
                        of the Privacy Policy.</p>
                    <p>This Privacy Policy was updated on April, 2024.</p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'privacypolicy',
    components: {
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
    },
    computed: {
    },
    created() {
    },
}
</script>